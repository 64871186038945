// function findNodes(nextNodeIds, all) {
//   return all.filter(o => nextNodeIds.includes(o.Id))
// }
function findNode(nextNodeId, all) {
  return all.find(o => nextNodeId === o.Id)
}

// function addNext(nodeId, parent, grandpa, all) {
//   const node = findNode(nodeId, all)
//   if (node.PrevNodes?.length > 1) {
//     //和parent同级
//     if (!grandpa.find(o => o === node)) {
//       grandpa.push(node)
//       addNextNodes(node.NextNodes, parent, grandpa, all)
//     }
//   } else {
//     parent.push(node)
//     addNextNodes(node.NextNodes, parent, grandpa, all)
//   }
// }

function addNextNodes(NextNodes, parent, grandpa, all) {
  if (!NextNodes.length) return
  NextNodes = NextNodes.map(o => findNode(o, all))
  const insert = NextNodes[0].PrevNodes.length > 1 ? grandpa : parent
  if (NextNodes.length > 1) {
    if (
      !insert
        .filter(o => o.isBranch)
        .map(o => o.branches[0][0])
        .some(o => NextNodes.includes(o))
    ) {
      const branchObj = { isBranch: 1, branches: NextNodes.map(o => [o]) }
      insert.push(branchObj)
      branchObj.branches.forEach(branch => {
        addNextNodes(branch[0].NextNodes, branch, insert, all)
      })
    }
  } else {
    if (!insert.includes(NextNodes[0])) {
      insert.push(NextNodes[0])
      addNextNodes(NextNodes[0].NextNodes, insert, insert, all)
    }
  }
}
// function _normalize() {}
/**
 * 将数组对象 格式化成 嵌套展示格式
 * @param {array} processScheme 后台丢出来的格式
 * @return {array} 用于前台生成样式，嵌套格式
 */
export function normalize(processScheme) {
  const nodes = []
  const head = processScheme.filter(o => !o.PrevNodes?.length)
  addNextNodes(
    head.map(o => o.Id),
    nodes,
    nodes,
    processScheme
  )
  return nodes
}

function getPrev(prev) {
  if (!prev) return []
  return prev.isBranch
    ? prev.branches
        .filter(o => o?.length)
        .map(o => getNext(o[o.length - 1]))
        .flat()
    : [prev.Id]
}
function getNext(next) {
  if (!next) return []
  // o[0].Id
  return next.isBranch
    ? next.branches
        .filter(o => o?.length)
        .map(o => getNext(o[0]))
        .flat()
    : [next.Id]
}
export function stringify(nodes, nextCycleNode, prevCycleNode) {
  let rtData = []
  nodes.forEach((n, i) => {
    if (n.isBranch) {
      n.branches.forEach(branch => {
        rtData = rtData.concat(stringify(branch, nodes[i + 1], nodes[i - 1]))
      })
    } else {
      // const NextNode = i === nodes.length - 1 ? nextCycleNode : nodes[i + 1]
      rtData.push(
        Object.assign(n, {
          PrevNodes: getPrev(i === 0 ? prevCycleNode : nodes[i - 1]),
          NextNodes: getNext(i === nodes.length - 1 ? nextCycleNode : nodes[i + 1]),
        })
      )
    }
  })
  return rtData
}
